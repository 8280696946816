import React, {useRef} from 'react';

// import Button from '@mui/material/Button';
import { Button } from '@material-ui/core';


import './InviteMembers.scss';

function InviteMembers({subdomain, refreshInvites, jwtToken, setToastMessage}) {

    let orgConfig = {};

    const textAreaEl = useRef(null);

    if(subdomain){
      orgConfig = require(`../../orgConfig/${subdomain}.json`);
    }

    const sendInvites = (event) => {
      let emails = processEmails(textAreaEl.current.value);

      for(let email of emails){
        const url = `${process.env.REACT_APP_API}/accountmanagement/${subdomain || "codetrack"}/invite/`

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${jwtToken}`
            },
            body:  JSON.stringify({email: email})
        };

        fetch(url, requestOptions)
        .then(response => response.json())
            .then(data => {
                if(data.status === 'success'){
                setToastMessage("Invite created");
                textAreaEl.current.value = '';
                refreshInvites();
                } else {
                    setToastMessage("There was an error while inviting member");
                    console.error('Unable to send invite.');
                }
            }).catch((error)=>{
                setToastMessage("There was an error while inviting member");
                console.error('Unable to send invite.', error);
            })
      }
    }

    const processEmails = (emailString) => {
      let emails = emailString.split(',');
      emails = emails.map(email => email.trim());
      //TODO validate emails
      return emails;
    }

    return (
        <div>
           <h3>Invite Members</h3>
           <p>Add any number of comma separated email addresses.</p>

           <textarea
            ref={textAreaEl}
            className="inviteMembers__textArea"
            aria-label="empty textarea"
            placeholder="newuser@domain.com"
          />
          <div className="inviteMembers__buttonDiv" >
            <Button  variant="contained" onClick={(e) => sendInvites(e)}>Send Invites</Button>
          </div>
        

        </div>
    );
}

export default InviteMembers;