import React, {useState} from 'react';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import './MemberListItem.scss';

function MemberListItem({member, handleRemoveMemberModal, handleBadgesSyncing, handleChangeEmploymentStatus, checked, setChecked, type="member"}) {

    const [isSyncing, setIsSyncing] = useState(false);

    // const handleToggle = (value) => () => {
    //     const currentIndex = checked.indexOf(value);
    //     const newChecked = [...checked];
    
    //     if (currentIndex === -1) {
    //       newChecked.push(value);
    //     } else {
    //       newChecked.splice(currentIndex, 1);
    //     }
    
    //     setChecked(newChecked);
    // };

    const handleSyncBadgesOnClick=async (id)=>{
        setIsSyncing(true);
        try{
            await handleBadgesSyncing(id);
        } catch(error){
            console.error(error);
        } finally {
            setIsSyncing(false);
        }
    }

    return (
            <TableRow
                key={member.id}
            >
                <TableCell align="center">{member.name}</TableCell>
                <TableCell align="center">{member.role}</TableCell>
                <TableCell align="center">{member.cohort || 'Unassociated'}</TableCell>
                <TableCell align="center">
                    <Checkbox
                        edge="start"
                        checked={member.employed}
                        onChange={()=>handleChangeEmploymentStatus(member.id, member.employed)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': 1 }}
                    />
                </TableCell>
                <TableCell className="memberListItem__actionsContainer" align="center">
                    <IconButton aria-label="sync badges"  onClick={() => handleSyncBadgesOnClick(member.id)}>
                        {isSyncing ? <RefreshOutlinedIcon style={{  animation: "rotate .6s infinite linear" }} /> : <ShieldOutlinedIcon />}
                    </IconButton>
                    <IconButton edge="end" aria-label="comments"  onClick={() => handleRemoveMemberModal(member.id)}>
                        <PersonRemoveIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
    );
}

export default MemberListItem;