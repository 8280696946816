export default {
    "codewars": {
        parseProblemLink: ({ url })=>{
            return url;
        },
        isValidateProblemUrl: ({ url })=>{
            return url.includes("www.codewars.com/kata/");
        }
    },
    "leetcode": {
        parseProblemLink: ({ url })=>{
            return url;
        },
        isValidateProblemUrl: ({ url })=>{
            return true;
        }
    },
    "web3": {
        parseProblemLink: ({ url, domain, userId })=>{
            let userQuery = userId ? `codeTrackId=${userId}&`: '';
            return domain + `?${userQuery}activate=web3&call=web3//startTutorial//${url}`;
        },
        isValidateProblemUrl: ({ url })=>{
            return true;
        }
    }
}