import React, {useState, useEffect} from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

import MemberListItem from '../memberListItem/MemberListItem';
import TextField from '@material-ui/core/TextField';

import './MemberList.scss';
import Modal from "../../modal/Modal";

function MemberList({role="Student", subdomain, jwtToken, setToastMessage}) {

    const [members, setMembers] = useState([]);
    const [checked, setChecked] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showRemoveMemberModal, setShowRemoveMemberModal] = useState(false);
    const [memberToRemove, setMemberToRemove] = useState(null);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    
    let organization = subdomain || 'codetrack';

    useEffect(() => {

        refreshMembers();

    }, [])

    const refreshMembers = () => {
        let url = `${process.env.REACT_APP_API}/users`;
       
        if(role == 'Staff'){
            url += `/staff/${organization}`;
        } else {
            url += `/organization/${organization}/allStudents`;
        }

        const requestOptions = {
            headers: {'Authorization': `Bearer ${jwtToken}`},
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setMembers(data);
            }).catch((error)=>{
                console.error(error);
            })
    }

    const filterUsers = (e) => {
        setSearchTerm(e.target.value.toLowerCase())
    }

    const handleRemoveMemberModal=(id)=>{
        setMemberToRemove(id);
        setShowRemoveMemberModal(true);
    }

    const removeMember = () => {

        const url = `${process.env.REACT_APP_API}/accountManagement/${organization}/removemember/${memberToRemove}`
 
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${jwtToken}`
            },
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.status === 'success'){
                    refreshMembers();
                    setToastMessage("Member removed");
                } else {
                    console.log('Unable to remove member');
                    setToastMessage("There was an error removing member");
                }
            
            }).catch((error)=>{
                console.error(error);
                setToastMessage("There was an error removing member");
            })
        setShowRemoveMemberModal(false);
        setMemberToRemove(null);
    }

    const handleBadgesSyncing= async(id)=>{
        let url = `${process.env.REACT_APP_API}/badges/sync/${id}`;
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${jwtToken}`
            },
        };
        try{
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if(!response.ok){
                throw new Error(data);
            } else {
                setToastMessage("Badges synced successfully");
            }
            await new Promise(resolve => setTimeout(resolve, 300));
        } catch(error){
            setToastMessage("There was an error syncing the badges");
            console.error(error);
        }
    }

    let filteredBySearch = searchTerm ? members.filter((member)=>{
        return member.name?.toLowerCase().includes(searchTerm.toLowerCase());
    }) : members;

    const handleChangeEmploymentStatus= async(id, currentStatus)=>{
        let url = `${process.env.REACT_APP_API}/users/${id}`;
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${jwtToken}`
            },
            body: JSON.stringify({
                employed: !currentStatus
            })
        };
        try{
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            if(!response.ok){
                throw new Error(data);
            } else {
                refreshMembers();
                setToastMessage("Employment status updated successfully");
            }
        } catch(error){
            setToastMessage("There was an error changing the employment status");
            console.error(error);
        }    
    }

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'role',
            numeric: false,
            disablePadding: false,
            label: 'Role'
        },
        {
            id: 'cohort',
            numeric: false,
            disablePadding: false,
            label: 'Cohort',
        },
        {
            id: 'employed',
            numeric: false,
            disablePadding: false,
            label: 'Employed',
        },
        {
            id: 'actions',
            numeric: false,
            disablePadding: false,
            label: 'Actions',
            disableSort: true
        }
    ];

    if(orderBy){
        members.sort(getComparator(order, orderBy));
    }

    return (
        <div className="memberList">
            <Modal showModal={showRemoveMemberModal} setShowModal={setShowRemoveMemberModal}>
                Are you sure you want to remove this member?
                <button onClick={removeMember}>Yes</button>
                <button onClick={()=>setShowRemoveMemberModal(false)}>No</button>
            </Modal>
          
            <div className="memberList__sorting">
                <h3> {role} List</h3>
                <div className="memberList__count"><span>{role} count:</span>{members.length}</div>
                <TextField id="search-user" label="Search" variant="outlined" onChange={(e) => filterUsers(e)} />
            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        {headCells.map((headCell) => {
                            return headCell.disableSort ? (
                                <TableCell align="center">{headCell.label}</TableCell>
                            ) : (      
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'center'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={createSortHandler(headCell.id)}
                                    >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            )
                        })}
                    </TableHead>
                    <TableBody>
                        {filteredBySearch.map((member, index) => {
                            return (
                                <MemberListItem 
                                    key={member.username + index} 
                                    member={member} 
                                    handleChangeEmploymentStatus={handleChangeEmploymentStatus}
                                    handleBadgesSyncing={handleBadgesSyncing}
                                    handleRemoveMemberModal={handleRemoveMemberModal} 
                                    checked={checked} 
                                    setChecked={setChecked} 
                                />
                            )
                        })}    
                    </TableBody>
                </Table>
            </TableContainer>           
        </div>
    );
}

export default MemberList;
