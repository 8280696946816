import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Link } from 'react-router-dom';  

import './Navbar.scss';

const NavBarLink = (props) => {

    return (
      <NavLink to={props.url}  onClick={props.onClick} >
        <li className={props.classNames}>
            {props.children}
        </li>
      </NavLink>
    )
}

const Navbar = ({menuData, loggedIn, setLoggedIn}) => {

    let history = useHistory();

    let {menuItems, logo} = menuData;
    const [expandMenu, setExpandMenu] = useState(false);

    menuItems = menuItems.filter(menuItem => {
        return !("loggedIn" in menuItem)|| menuItem.loggedIn == loggedIn;

    })

    const logoutUser = () => {
        localStorage.removeItem("accessToken");
        setLoggedIn(false);
        setExpandMenu(false);
        history.push('/')
    }
    
    const menuItemElements = menuItems.map((menuItem, index) => {
        if(menuItem.text === 'Log Out'){
            return (
                <li  key={index} onClick={() => logoutUser()}>
                    {menuItem.text}
                </li>
            )
        }
       
        return (
            <NavBarLink key={index} url={menuItem.url}   onClick={()=>setExpandMenu(false)}>
                {menuItem.text}
            </NavBarLink>
        )
    })

    return (
        <nav>
            <div className="nav-items">
                
                    <Link to="/">
                        
                        {logo.src && 
                            <div className="logo_container left">
                                <img src={logo.src} className="logo" alt="Logo" />
                            </div> 
                        }
                        {logo.text && 
                            <span className="logo left">{logo.text}</span>
                        }
                    </Link>
                
                
                <div className="condensed-menu-icon right"  onClick={()=>setExpandMenu(!expandMenu)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
               
                <ul className={ "expanded-menu-items right " + (expandMenu ? "active" : "")} >
                    {menuItemElements}
                </ul>
            </div>
      </nav>    
    )
}


export default Navbar;