
import React, {useEffect, useState} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ReactGA from "react-ga4";

import ScrollToTop from './ScrollToTop';

import Home from './pages/Home';
import Sales from './pages/Sales';
import LandingPage from './pages/LandingPage';
import FellowDetail from './pages/FellowDetail';
import HowToUse from './pages/HowToUse';
import Navbar from "./layout/navbar/Navbar";
import Clan from './pages/Clan';
import OneGreatProject from './pages/OneGreatProject';
import LandingYourFirstJob from './pages/LandingYourFirstJob';
import Login from './pages/Login';
import CreateAccount from './pages/CreateAccount';
import OrgAccountManagement from "./pages/OrgAccountManagement";
import CreateProblemSet from "./pages/CreateProblemSet";
import EditProblemSet from "./pages/editProblemSet/EditProblemSet";
import ProblemSets from "./pages/problemSets/ProblemSets";
import Videos from "./pages/Videos";


import './App.scss';
import SecrurityCodeGenerator from "./securityCodeGenerator/SecrurityCodeGenerator";
import Footer from "./layout/footer/Footer";
import CreatePassword from "./pages/CreatePassword";


const host = window.location.host;
const arr = host.split('.');
const subdomain = arr.length > 1 && arr[0] !== 'www' ? arr[0] : '';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize("G-8V1B3H1Q6K");
  ReactGA.send("pageview");
}

let pursuitMenu = {
  "logo" : {
      "src": "/pursuit_logo.png"
  },
 "menuItems" : [ 
      {
        "text" : "How To Use",
        "url" : "/howToUse"
      },
      {
        "text" : "Log In", 
        "url" : "/login", 
        "loggedIn" : false
      },
      {
        "text" : "Create Account", 
        "url" : "/createAccount", 
        "loggedIn" : false
      },
      {
        "text" : "Videos", 
        "url" : "/videos",
        "loggedIn" : true
      },
      {
        "text" : "Profile", 
        "url" : "/profile", 
        "loggedIn" : true
      },
      {
        "text" : "Log Out", 
        "url" : "/logout", 
        "loggedIn" : true
      }
 ]
}

let web3Menu = {
    "logo" : {
        "src": "/pursuit_logo.png"
    },
   "menuItems" : [ 
        {
          "text" : "How To Use",
          "url" : "/howToUse"
        },
        {
          "text" : "Log In", 
          "url" : "/login", 
          "loggedIn" : false
        },
        {
          "text" : "Create Account", 
          "url" : "/createAccount", 
          "loggedIn" : false
        },
        {
          "text" : "Videos", 
          "url" : "/videos",
          "loggedIn" : true
        },
        {
          "text" : "Profile", 
          "url" : "/profile", 
          "loggedIn" : true
        },
        {
          "text" : "Log Out", 
          "url" : "/logout", 
          "loggedIn" : true
        }
   ]
  }

let codepathMenu = {
  "logo" : {
    "src": "/codepath_logo.jpeg"
},
 "menuItems" : [ 
      {
        "text" : "How To Use",
        "url" : "/howToUse"
      },
      {
        "text" : "Log In", 
        "url" : "/login", 
        "loggedIn" : false
      },
      {
        "text" : "Create Account", 
        "url" : "/createAccount", 
        "loggedIn" : false
      },
      {
        "text" : "Profile", 
        "url" : "/profile", 
        "loggedIn" : true
      },
      {
        "text" : "Log Out", 
        "url" : "/logout", 
        "loggedIn" : true
      }
 ]
}

let rootNav = {
  "logo" : {
    "src": "/codetrack_logo.png"
},
"menuItems" : [ 
    {
      "text" : "Bootcamps",
      "url" : "/learnMore",
      "loggedIn" : false
    },
    {
      "text" : "Universities",
      "url" : "/learnMore",
      "loggedIn" : false
    },
    {
      "text" : "How To Use",
      "url" : "/howToUse"
    }, 
    {
      "text" : "Videos", 
      "url" : "/videos",
      "loggedIn" : true
    },
    {
      "text" : "Log in", 
      "url" : "/login",
      "loggedIn" : false
    },
    {
      "text" : "Sign up for free", 
      "url" : "/createAccount",
      "loggedIn" : false

    },
    {
      "text" : "Profile", 
      "url" : "/profile", 
      "loggedIn" : true
    },
    {
      "text" : "Log Out", 
      "url" : "/logout", 
      "loggedIn" : true
    }]
}

let navData;

switch(subdomain){
  case 'pursuit':
    navData = pursuitMenu;
    break;
  case 'codepath':
    navData = codepathMenu;
    break;
  case 'web3':
    navData = web3Menu;
    break;
  default:
    navData = rootNav;
}

function App() {

  let orgConfig = {};

  if(subdomain){
    orgConfig = require(`./orgConfig/${subdomain}.json`);
  }

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('accessToken') ? true : false);
  const [theme, setTheme] = useState(orgConfig.orgName || 'codetrack');
  const [userPermissions, setUserPermissions] = useState([]);

  const handleGetPermissions=async()=>{
    try{
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ localStorage.getItem('accessToken')}`
            }
        };
        let res = await fetch(`${process.env.REACT_APP_API}/users/permissions`, requestOptions);
        let { permissions } = await res.json();
        if(res.ok){
            setUserPermissions(permissions);
        } else {
            setUserPermissions(["unauthorized"]);
        }

    } catch(err){
        console.error(err);
    }
  }

    useEffect(()=>{
        handleGetPermissions();
    },[]);

  return (
    <div data-theme={theme}>
      <Router>
          <ScrollToTop />
          <Navbar menuData={navData} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
            <div className="App">
          <Switch>
            <Route path="/howToUse">
              <HowToUse />
            </Route>
            <Route path="/accountManagement">
              <OrgAccountManagement  subdomain={subdomain} orgConfig={orgConfig} />
            </Route>
            <Route path="/login">
              <Login setLoggedIn={setLoggedIn} subdomain={subdomain} />
            </Route>
            <Route path="/createPassword">
              <CreatePassword subdomain={subdomain} />
            </Route>
            <Route path="/profile" component={props => <FellowDetail {...subdomain} />} />
            <Route path="/generateCode">
              <SecrurityCodeGenerator subdomain={subdomain} />
            </Route>
            <Route path="/createAccount">
              <CreateAccount setLoggedIn={setLoggedIn}  subdomain={subdomain} />
            </Route>
            <Route path="/fellow/:fellowId">
              <FellowDetail subdomain={subdomain} orgConfig={orgConfig} />
            </Route>
            <Route path="/clans/:clanName">
              <Clan />
            </Route>
            <Route path="/one-great-project">
              <OneGreatProject/>
            </Route>
            <Route path="/landing-your-first-job">
              <LandingYourFirstJob/>
            </Route>
            <Route path="/problemsets/:problemSetId/edit">
              <EditProblemSet/>
            </Route>
            <Route path="/problemsets/new">
              <CreateProblemSet/>
            </Route>
            <Route path="/problemsets">
              <ProblemSets userPermissions={userPermissions}s />
            </Route>
            <Route path="/learnMore">
              <Sales/>
            </Route>
            <Route path="/videos">
              <Videos/>
            </Route>
      
            <Route path="/">
              {!subdomain && loggedIn &&  <Home subdomain={subdomain} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}
              {!subdomain && !loggedIn &&  <LandingPage />}
              {subdomain &&  <Home subdomain={subdomain} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>}
            </Route>
          </Switch>
          <Footer/>
        </div>
      </Router>
    </div>
  );
}

export default App;
