import React from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import Announcement from '../components/announcement/Announcement';


import Button from '@material-ui/core/Button';

import './LandingPage.scss';

function LandingPage(props) {
    return (
        <div className="landingPage">
            <div className="landingPage__miniTitle">CodeTrack for Developers</div>
            <div className="landingPage__title">Get on track to your first job as a software developer.</div>
            <div className="landingPage__subtitle">
               CodeTrack is a free platform to compete and collaborate in preparing for a job as a software developer.
            </div>
            <div className="landingPage__callToAction"></div>
            <div className="landingPage__callToAction">
                <Link to='/createAccount'>
                    <Button
                        variant="contained"
                        color="primary"
                        >
                            Create an Account
                    </Button>
                </Link>
            </div>
            <p className="landingPage__text">CodeTrack leverages platforms like LeetCode, Codewars, Codility and Github to lay a path from rookie to job-ready. 
                You will score points by completing challenges at your own pace as you move through a variety of topics.
                If you get stuck, there are instructional videos along the way demonstrating how to follow test driven development, 
                how to break down a problem and much more. 
            </p>
            <p className="landingPage__text">
                CodeTrack was created by a self-taught developer who knows how daunting it can be to land your first job, especially 
                if you come from a non-traditional background. 
            </p>
            <hr />
            <div className="sales_testimonials">
                <div className="sales__subtitle"> What Other Devs are Saying</div>
                    <Carousel>
                        <div>
                            <div className="card-info" style={{marginTop: "15px"}}>
                                <div className="name">Jordan B.</div>
                                <div className="role">Engineer @ Uber</div>
                                <div className="content">CodeTrack has all the tools to help you dominate data structures and algorithms! It gave me the confidence to focus more on improving my behavioral skills!</div>
                            </div>
                        </div>
                        <div className="card-info" style={{marginTop: "15px"}}>
                            <div className="name">Daniel N. </div>
                            <div className="role">Engineer @ Uber</div>
                            <div className="content">
                                <p>I'd like to shout out CodeTrack for starting a friendly coding competition among peers. It helped me improve my logic skills and solidify the fundamentals of JavaScript. The structure of CodeTrack, organized by category, allowed me to start from scratch and finish strong with data structures and algorithms.</p>
                                <p>I'm happy to say that the CodeTrack motivated me and helped me accumulate the necessary experience in order to successfully implement algorithms during interviews.</p>
                            </div>
                        </div>
                        <div className="card-info" style={{marginTop: "15px"}}>
                            <div className="name">Obaid R. </div>
                            <div className="role">Engineer @ Uber</div>
                            <div className="content">
                                <p>CodeTrack was an invaluable tool for me. It provided a progressive route for me to practice the fundamentals and use them as building blocks to solve harder and harder problems. It also sharpened my ability with test-driven development.</p>
                                <p>It allowed me to compete in a fun and constructive way with my peers. We tried to beat each other's points on a weekly basis and as a result we collectively strengthened our coding skills.</p>
                                <p>It really helped me prepare for my interview both technically and psychologically. It definitely gets progressively harder though so start with the easy problems and work your way up!</p>
                            </div>
                        </div>
                    </Carousel>
            </div>
        </div>
    );
}

export default LandingPage;