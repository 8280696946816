import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'

import CodewarsChallengesCollection from '../../../codewarsChallengesCollection/CodewarsChallengesCollection';
import CodilityChallengesCollection from '../../../codilityChallengesCollection/CodilityChallengesCollection';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import './ProblemSets.scss';
import ShadowBox from '../../../layoutComponents/shadowBox/ShadowBox';
import PlatformChallengesCollection from '../../../platformChallengesCollection/PlatformChallengesCollection';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <Typography>{children}</Typography>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function ProblemSets({playerInfo, orgConfig}) {

    let history = useHistory();
    const githubUsername = playerInfo.github_username;
    const {id, username, language} = playerInfo;

    let tabsToDisplay = [
        "intro",
        "beginner",
        "intermediate",
        "assigned-problems",
        "algorithms",
        "data-structures",
        "codility"
    ];
    
    if(orgConfig.tabs.length > 0){
        tabsToDisplay = orgConfig.tabs;
    }

    let tabValue = 0;

    if(window.location.hash){
        let hash = window.location.hash.slice(1);
        let index = tabsToDisplay.indexOf(hash);
        tabValue = index !== -1 ? index : 0;
    }

    const [problems, setProblems] = useState([]);
    const [assignedProblemSets, setAssignedProblemSets] = useState([]);
    const [value, setValue] = useState(tabValue);

    useEffect(async () => {
        if(username){
            fetch(`${process.env.REACT_APP_API}/codewars/${username}`)
                .then(res => res.json())
                .then((result) => {
                    setProblems(result);
                }).catch((error) => {
                    console.log(error);
                })
        }
        const jwtToken =  localStorage.getItem('accessToken');
        const requestOptions = {
            headers: {'Authorization': `Bearer ${jwtToken}`},
        };
        let res = await fetch(`${process.env.REACT_APP_API}/problemSets/assigned`, requestOptions)
        let data = await res.json();
        if(res.ok){
            setAssignedProblemSets(data);
        } else {
            console.error(data);
        }
    }, []);

    const handleChange = (event, newValue) => {
        let hash = `#${tabsToDisplay[newValue]}`;
        history.replace(window.location.pathname + hash)
        setValue(newValue);
    };
    const findTabIndex=(name)=>{
        return tabsToDisplay.indexOf(name);
    }
    return (
        <ShadowBox className="codeChallenges" title="Code Challenges" flex>
            <Tabs value={value} className="sideTabs" onChange={handleChange} orientation="vertical" >
                
                { tabsToDisplay.map((tabName, index)=>{
                    return <Tab key={tabName + index} label={tabName.replace("-", " ")} {...a11yProps(index)} />
                }) }

            </Tabs>
            <div className="challengeLists">
                <TabPanel value={value} index={findTabIndex("intro")}>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="grasshopper" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="grasshopper-debug" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="strings1" />                    
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("beginner")}>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="strings2" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="arraysIntro" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="hashTables" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="controlFlow" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="mathIntro"/>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="OOP" />
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("intermediate")}>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="strings3" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="arrays" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="objects" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="higherOrderFunctions" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="matrixes" />
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("assigned-problems")}>
                    { assignedProblemSets.map((problemSet)=>{
                        return <PlatformChallengesCollection problemSet={problemSet} userId={id} />;
                    }) }
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("algorithms")}>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="recursion" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="cryptography" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="bubbleSort" />
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("data-structures")}>
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="linkedLists" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="queues" />
                    <CodewarsChallengesCollection language={language} problems={problems} problemSet="binaryTree" />
                </TabPanel>
                <TabPanel value={value} index={findTabIndex("codility")}>
                    <p>For Codility problems, please submit the url to your submitted solution next to the completed problem.</p>
                    <CodilityChallengesCollection userId={id} language={language} problems={problems} problemSet="codility"/>
                    <CodilityChallengesCollection userId={id} language={language} problems={problems} problemSet="stacksCodility"/>
                </TabPanel>
            </div>
        </ShadowBox>
    );
}

export default ProblemSets;