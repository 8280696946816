import React, {useState, useEffect} from 'react';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

import './PointsBreakdown.scss';

function PointsBreakdown({playerInfo}) {

    const [leetCodeData, setLeetCodeData] = useState({});

    useEffect(() => {
        if(playerInfo.leetcodeid){

            let leetCodeId = playerInfo.leetcodeid;

            fetch(`${process.env.REACT_APP_API}/leetcode/${playerInfo.id}/${leetCodeId}`)
            .then(res => res.json())
            .then((result) => {

                setLeetCodeData(result)
            },
             
              (error) => {
                console.log(error);
              }
            )
        }
    }, [playerInfo]);

    const leetCodeProblems = {
        easy: leetCodeData.easy || 0,
        medium: leetCodeData.medium || 0,
        hard: leetCodeData.hard || 0
    }
    return (
        <ShadowBox title="Point Breakdown">
            <div className="pointsBreakdown">
                
                <div className="pointsBreakdown__source">
                    <div className="pointsBreakdown__label">Codewars:</div>
                    <div>{playerInfo.honor}</div>
                </div>
                
                <div className="pointsBreakdown__source">
                    <div className="pointsBreakdown__label">Leetcode:</div>
                    <div>{playerInfo.leetcode_points}</div>
                </div>
                <div className="pointsBreakdown__leetcodeProblems">
                    <table>
                            {Object.keys(leetCodeProblems).map(key => {
                                const formattedKey = key[0].toUpperCase() + key.slice(1);
                                return (
                                    <tr>
                                        <td>{formattedKey}:</td>
                                        <td>{leetCodeProblems[key]}</td>
                                    </tr>
                                )
                            }) 
                            }
                    </table>
                </div>
            
                <div className="pointsBreakdown__source">
                    <div className="pointsBreakdown__label">Total:</div>
                    <div>{playerInfo.honor + playerInfo.leetcode_points}</div>
                </div>     
            </div>
        </ShadowBox>
    );
}

export default PointsBreakdown;