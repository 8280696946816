import React, {useState, useEffect} from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";

import './FellowDetail.scss';

import ProblemSets from './studentDetailSubviews/problemSets/ProblemSets';
import Dashboard from './studentDetailSubviews/Dashboard';
import Portfolio from './studentDetailSubviews/Portfolio';
import ManageAcct from './studentDetailSubviews/manageAcct/ManageAcct';

import UserIcon from '@mui/icons-material/Dashboard';
import ChallengeIcon from '@mui/icons-material/HomeWorkRounded';
import PortfolioIcon from '@mui/icons-material/Work';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

function FellowDetail({ orgConfig }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let { fellowId } = useParams();

  const location = useLocation();
  let history = useHistory();

  const [playerInfo, setPlayerInfo] = useState(location?.state?.playerInfo || {});
  const [subview, setSubview] = useState('dashboard');
  const [profile, setProfile] = useState(location.pathname.includes('profile'));

    useEffect(() => {
      if(Object.keys(playerInfo).length < 1 && !profile){
        fetch(`${process.env.REACT_APP_API}/users/${fellowId}`)
          .then(res => res.json())
          .then((result) => {
              setPlayerInfo(result)
          },
            
            (error) => {
              console.log(error);
            }
        )
      }
    }, []);

    useEffect(() => {
      if(profile){
        const requestOptions = {
            headers: {'Authorization': `Bearer ${ localStorage.getItem('accessToken')}`},
        };

        fetch(`${process.env.REACT_APP_API}/users/profile`, requestOptions)
        .then(response => response.json())
        .then(data => {

          if(data.error){
                history.push('/login');
            } else {
                setPlayerInfo(data);
            }
        }).catch(err => {
          console.log(err)
          history.push('/login');
        })
      }
  }, [])

    return (
        <div className="pageContainer">
          <div className="fellowDetailLayout">
            <div className="sideMenu">
              <UserIcon onClick={() => setSubview('dashboard')} />
              <ChallengeIcon onClick={() => setSubview('codeChallenges')} />
              <PortfolioIcon onClick={() => setSubview('portfolio')}/>
              {profile && <ManageAccountsIcon onClick={() => setSubview('accountSettings')}/>}
            </div>
            <div className="pageContent">
              {subview === 'dashboard' && <Dashboard playerInfo={playerInfo} /> }
              {subview === 'codeChallenges' &&   <ProblemSets playerInfo={playerInfo} orgConfig={orgConfig} /> }
              {subview === 'portfolio' &&   <Portfolio playerInfo={playerInfo}/> }
              {subview === 'accountSettings' && <ManageAcct playerInfo={playerInfo} /> }
            </div>
          </div>
         

        </div>
    );
}

export default FellowDetail;