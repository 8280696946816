import React, { useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';

import Collapsible from 'react-collapsible';

import './PlatformChallengesCollection.scss';
import platformMethods from '../utils/platform-methods';

const ProblemSetHeader = ({title, completedCount, problemCount}) => {
    completedCount = completedCount || 0;
    return (
        <div className="problemSetHeader">
            <span className="problemSetHeader__title">{title}</span>
            <span className="problemSetHeader__count"> Complete: {completedCount}/{problemCount}</span>
        </div>
    )
}

const PlatformChallengesCollection = ({problemSet, userId}) => {
    
    let completedCount = 0;
    let problemElements = problemSet.problems.map((problem) => {

        if(problem.did_complete){
            completedCount++;
        }
        let challengeLevel = problem.rank ? Math.abs(problem.rank.id) : "";
        let { title, platform_name, domain, url, did_complete, videoUrl } = problem;
        let link = platformMethods[platform_name]?.parseProblemLink({url, domain, userId});
        
        return (
            <div className="codewarsChallenge">
                <a href={ link } target="_blank">
                    
                    <Checkbox
                        color="primary"
                        onClick={null}
                        checked = {!!did_complete}
                        disabled = {!did_complete}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    
                    <span className="codewarsChallenge__title">{title}</span>
                    <span className="codewarsChallenge__rank">{challengeLevel}</span>
                    {videoUrl && 
                        <a className="codewarsChallenge__videoUrl" href={videoUrl} target="_blank">
                            <Button
                                variant="contained"
                                color="primary"
                            >
                                Video Lesson
                            </Button>
                        </a>
                    }
                </a>
            </div>
        )
    })

    return (
        <Collapsible 
            trigger={<ProblemSetHeader title={problemSet.title || "Starting Problems"} problemCount={problemSet.problems.length} completedCount={completedCount} />} 
            classParentString="challengeCollection"
            open={completedCount != problemSet.problems.length }
        >
            <div className="collectionOfPlatformChallenges__container">
                {problemElements}
            </div>
        </Collapsible>
    );
}

export default PlatformChallengesCollection;