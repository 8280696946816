import React, {useState} from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import EmailInput from '../components/inputs/EmailInput';
import PasswordInput from '../components/inputs/PasswordInput';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: "10px 0 !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  }));

function Login({setLoggedIn, subdomain}) {

  let orgConfig = {};

    if(subdomain){
      orgConfig = require(`../orgConfig/${subdomain}.json`);
    }

    const location = useLocation();
    const classes = useStyles();

    let history = useHistory();
    
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorsArr, setErrorsArr] = useState([]);
    const [loading, setLoading] = useState(false);
     
    const handleChange = (e) => {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        switch(name) {
            case 'password':
                setPassword(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default: 
                break;
        }

  }

  const handleSubmit = (e) => {
    
    e.preventDefault();
    setLoading(true);

    if(errorsArr.length) {
      setErrorMessage("Please resolve inputs before submitting.");
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }


    if(!email || !password) {
      setErrorMessage("Please provide required values.");
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    };

    fetch(`${process.env.REACT_APP_API}/auth/login`, requestOptions)
    .then(response => response.json())
    .then(result => {
        setLoading(false);
        if(result.error){
            setErrorMessage(result.error);
        } else {
            setLoggedIn(true)
            if(result.organization == 'pursuit' && subdomain != 'pursuit'){
              let uri = new URL(`http://pursuit.codetrack.dev?accessToken=${result.accessToken}`);
              window.location.assign(uri.href);
            } else {
              localStorage.setItem('accessToken', result.accessToken);
              history.push('/');
            }
           
        }
       
    },
     
    (error) => {
      setLoading(false);
      console.log(error);
    })
  }

    return (
        <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
          </Avatar>
          <Typography component="h1" variant="h5">
            Log In
          </Typography>
          {errorMessage &&
            <div style={{"color" : "red"}}>  {errorMessage}</div>
          }
        {subdomain === 'pursuit' &&
          <>
            <p>
                <span style={{"fontWeight" : "600"}}>Note: </span>CodeTrack is adding full account access with password protected routes. 
            </p>
            <p  style={{"fontWeight" : "600"}}>
                If you have never selected a password, please <Link to={'/createPassword'}><span style={{"fontSize": "20px" }} >click here</span></Link>. 
            </p>
          </>
          }
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <EmailInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr} orgConfig={orgConfig} />
              <PasswordInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr}/>             
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              loading={loading}
            >
              Log In
            </LoadingButton>
          </form>
        </div>
        <Box mt={5}>
        </Box>
      </Container>
    );
}

export default Login;