import React, {useState, useEffect} from 'react';
import { useParams} from "react-router-dom";

import { Button, Input } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


import Box from '@mui/material/Box';

import Modal from "../../modal/Modal";

import CodeChallenge from '../../components/codeChallenge/CodeChallenge';

import ProblemTable from '../../components/problemTable/ProblemTable';

import './EditProblemSet.scss';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import platformMethods from '../../utils/platform-methods';

const EditProblemSet = (props) => {

    const {problemSetId} = useParams(); 

    const [problemSet, setProblemSet] = useState({problems:[]});
    const [title, setTitle] = useState("");
    const [problemUrl, setProblemUrl] = useState("");
    const [toastMessage, setToastMessage] = useState("");
    const [isSourceValid, setIsSourceValid] = useState(false);
    const [assignableCohorts, setAssignableCohorts] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState("codewars");
    
    let platforms = [ "Codewars", "Leetcode", "Web3" ];

    useEffect(() => {
        refreshProblems();
        fetchAssignableCohorts();
    }, []);

    const fetchAssignableCohorts= async()=>{
        try{
            const jwtToken =  localStorage.getItem('accessToken');
            const requestOptions = {
                headers: {'Authorization': `Bearer ${jwtToken}`},
            };
            let res = await fetch(`${process.env.REACT_APP_API}/problemSets/assignable/cohorts`, requestOptions)
            let data = await res.json();
            if(res.ok){
                setAssignableCohorts(data);
            } else {
                console.error(data);
            }
        } catch(error) {
            console.error(error);
        }
    }

    const refreshProblems = () => {
        fetch(`${process.env.REACT_APP_API}/problemsets/${problemSetId}`)
            .then(response => response.json())
            .then(data => {
                setProblemSet(data);
                setTitle(data.title);
            })
    }

    const updateTitle = (e) => {
        setTitle(e.target.value);
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const handleAddingProblem=async()=>{
        setToastMessage("Problem added");        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ platformName: selectedPlatform, url: problemUrl, problemSetId: problemSet.id })
        };
        let response = await fetch(`${process.env.REACT_APP_API}/problems`, requestOptions);
        let data = await response.json();
        if(!response.ok){
            if(data.message){
                setToastMessage(data.message);
            } else {
                setToastMessage("An error occurred while adding problem");
            }
        }
        refreshProblems();
        setIsSourceValid(false);
        setProblemUrl("");       
    }

    const validateProblemUrl=(url, platform)=>{
        let isValid = platformMethods[platform].isValidateProblemUrl( { url } );
        setIsSourceValid(isValid);
    }

    const handleProblemSourceInput=(e)=>{
        let url = e.target.value.replace(/^https?:\/\//, '');
        validateProblemUrl(url, selectedPlatform);
        setProblemUrl(url);
    }
    
    const handleCohortAssign= async(event, cohort)=>{
        try{
            const jwtToken =  localStorage.getItem('accessToken');
            const requestOptions = {
                method: event.target.checked ? "POST": "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: JSON.stringify({
                    cohortId: cohort.id, 
                    problemsetId: problemSetId
                })
            };
            let res = await fetch(`${process.env.REACT_APP_API}/problemSets/assign`, requestOptions)
            let data = await res.json();
            if(res.ok){
                fetchAssignableCohorts();
            } else {
                console.error(data);
            }
        } catch(error) {
            console.error(error);
        }
    }

    const handleSelectPlatform=(e)=>{
        validateProblemUrl(problemUrl, e.target.value);
        setSelectedPlatform(e.target.value);
    }

    return (
        <div className="editProblemSet">
             {/* <Modal showModal={showRemoveMemberModal} setShowModal={setShowRemoveMemberModal}>
                Are you sure you want to remove this member?
                <button onClick={removeMember}>Yes</button>
                <button onClick={()=>setShowRemoveMemberModal(false)}>No</button>
            </Modal> */}
            <Snackbar
                open={!!toastMessage}
                autoHideDuration={2000}
                onClose={() => setToastMessage("")}
                message={toastMessage}
                anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
            />
            <h3>Edit Problem Set</h3>
            <div className="twoColumns">
                <div className="editProblemSet-content">
                    <TextField 
                        id="set-title" 
                        value={title} 
                        label="Title" 
                        variant="outlined" 
                        fullWidth
                        onChange={(e) => updateTitle(e)} 
                    />
                    <Box className="twoColumns">
                        <Box className="twoColumns">
                            <TextField 
                                id="set-title" 
                                value={problemUrl} 
                                label="Please enter problem url" 
                                variant="outlined" 
                                onChange={handleProblemSourceInput} 
                            />
                            <Select
                                id="platform-select"
                                value={selectedPlatform}
                                label="Platform"
                                onChange={handleSelectPlatform}
                            >
                                { platforms.map((platform)=>{
                                    return <MenuItem value={platform.toLowerCase()}>{platform}</MenuItem>
                                }) }
                            </Select>
                            
                        </Box>
                        <Button 
                            variant="contained"
                            color="primary"
                            onClick={handleAddingProblem}
                            disabled={!isSourceValid}
                        >
                            Add
                        </Button>
                    </Box>
                    <Box>
                        { problemSet.problems.length ? (
                            <ProblemTable problemSet={problemSet} refreshProblems={refreshProblems} setToastMessage={setToastMessage} />
                        ) : (
                            <div style={{textAlign:"center"}}>No problems found</div>
                        )}
                    </Box>
                </div>
                
                <ShadowBox title="Assign to:">
                    { assignableCohorts.map((cohort)=>{
                        return <FormControlLabel control={<Checkbox onChange={(event)=>handleCohortAssign(event, cohort)} checked={!!cohort.problemSets?.includes(Number(problemSetId))} />} label={cohort.name} />
                    }) }
                </ShadowBox>
            </div>
        </div>
    )
}

export default EditProblemSet;