import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function NameInput({handleChange, errorsArr, setErrorsArr}) {

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const validateName = (name) => {

        if(name.length < 2){
            setError(true);
            let tempErrorsArr = errorsArr.filter(i =>  i !== 'name');
            setErrorsArr([...tempErrorsArr, "name"])
            setHelperText("Please provide a name of two characters or more.")
            return;
        } 
        
        setError(false);
        setHelperText(""); 
        let tempErrorsArr = errorsArr.filter(i =>  i !== 'name');
        setErrorsArr([...tempErrorsArr])    
    }

    return (
        <Grid item xs={12}>
            <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Name"
                error={error}
                helperText={helperText}
                onChange={handleChange}
                onBlur={(e) => validateName(e.target.value)}
                autoFocus
            />
        </Grid>
    );
}

export default NameInput;