import React, { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';

import './InviteList.scss';
import { Button } from '@mui/material';

const InviteText = ({invite, cancelInvite}) => {

    const formatDate = (date) => {
        date = date.split('T')[0].split('-');
        return `${date[1]}/${date[2]}/${date[0]}`
    }
    const date = formatDate(invite.sent_on);

    return (
        <TableRow
            key={invite.id}
        >
            <TableCell align="center">{invite.email}</TableCell>
            <TableCell align="center">{date}</TableCell>
            <TableCell align="center"><Button onClick={()=>cancelInvite(invite.id)}><DeleteIcon /></Button></TableCell>
        </TableRow>
    )
}


function InviteList({invites, refreshInvites, subdomain="codetrack", jwtToken, setToastMessage}) {

    // const [checked, setChecked] = useState([]);

    const cancelInvite = (id) => {

        const url = `${process.env.REACT_APP_API}/accountmanagement/${subdomain || "codetrack"}/invite/${id}`;

        const requestOptions = {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${jwtToken}`},
        };

        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                setToastMessage("Invite cancelled");
                refreshInvites();
            }).catch((error)=>{
                console.log(error);
                setToastMessage("There was an error removing invite");
            })
    }

    return (
        <div className="inviteList">
            <h3>Pending Invites</h3>
            <TableContainer component={Paper}>
                { invites.length > 0 ? (
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Sent On</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invites.map(( invite, index ) =>{
                                return <InviteText key={index} invite={invite} cancelInvite={cancelInvite} />
                            })}
                        </TableBody>
                    </Table>
                ):(
                    <p style={{textAlign:"center"}}>No pending invites</p>
                )}
            </TableContainer>
        </div>
    );
}

export default InviteList;