import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";

import PlayerCardCollection from '../components/playerCardCollection/PlayerCardCollection';


function Clan(props) {

    let { clanName } = useParams();

    let formattedClanName = clanName[0].toUpperCase() + clanName.substring(1);

    let url = 'clans/' + clanName;

    return (
        <div>
            <h1>{formattedClanName}</h1>
            <PlayerCardCollection url={url} topScorers />
            <PlayerCardCollection url={url} />
            <PlayerCardCollection url={url} staff/>
        
        </div>
    );

}

export default Clan;