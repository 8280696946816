import React from 'react';

function Videos(props) {
    return (
        <div className="videos">
            <h3>Videos</h3>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/cA4867KVME1KYRRwv-1sOE3ZPW4ulKIDBZd2NzuIUEAAZIuutqBgFeVVVIkmUF30.OI2rQ2DaFzDqzfEZ"
                    target="_blank"
                >
                    July 24th: Well of Ideas - Harder Version (90 Min Class)
                </a>
            </div>
            <div>
                <a 
                    href="https://us06web.zoom.us/rec/share/GMH3XDzXVD1bbOddeOdizfBnZiZM1p5QO8MkXynJhkYNUPXK6OxvBdK6-Y_OGkxS.R9vi8NIyKO8dixM6?startTime=1659276216000"
                    target="_blank"
                >
                    July 31st: String Shortener (90 Min Class)
                </a>
            </div>
        </div>
    );
}

export default Videos;