import React from 'react';
import { Checkbox } from '@material-ui/core';

import './SelectorCard.scss';

function SelectorCard({text, action, cohorts}) {

    const cardClicked = () => {
        action(!cohorts.includes(text), text);
    }

    return (
        <div className="selectorCard" onClick={() => cardClicked()}>
            <Checkbox
                checked={cohorts.includes(text)}
                inputProps={{
                    "data-testid": "checkbox",
                    'aria-label': 'primary checkbox'
                }}
            />
            {text}
        </div>
    );
}

export default SelectorCard;