import React, {useEffect, useState} from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { Button } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

import TrackProjectInstructions from '../trackProjectInstructions/TrackProjectInstructions';

import './CommitsLog.scss';

function CommitsLog({username}) {


    const [commits, setCommits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showInstructions, setShowInstructions] = useState(false);

    useEffect(function(){
        fetch(`${process.env.REACT_APP_API}/users/${username}/commits`)
        .then(res => res.json())
        .then( result => {
            setLoading(false);
            setCommits(result);
        }, 
            (error) => {
                console.log(error);
            }
        )
    }, []);
    
    const formatDate = (date) => {
        date = date.split('T')[0].split('-');
        let month = date[1];
        let day = date[2];
        if(month[0] === '0') month = month[1]
        if(day[0] === '0') day = day[1]

        return month + '/' + day 
    }

    let repoName;
    let repoNameChanged = false;

    return (
        <div className="commitsLog">
            <div className="commitsLog__header">
                <div className="commitsLog__title">Commits Tracker</div>
                {!loading && commits.length > 0 && 
                <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => setShowInstructions(!showInstructions)}
                >
                    How to Add a Project
                </Button>
}
            </div>
            {loading && <div className="commitsLog__loadingView">Loading...</div>}
            {!loading && (commits.length < 1 || showInstructions) && 
                <TrackProjectInstructions />
            }
            {commits.length > 0 &&
                <TableContainer component={Paper}>
                    <Table  size="small" data-testid="commitsLog__tableOfCommits">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Commit Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commits.map((commit, index) => {

                                let date = formatDate(commit.created_at);
                            
                                repoNameChanged = false;
                                if(commit.repo_name != repoName){
                                    repoNameChanged = true;
                                    repoName = commit.repo_name;
                                }
                            
                                return (
                                    <>
                                        { repoNameChanged ? 
                                            <TableRow >
                                                <TableCell className="commitsLog__repoName">{commit.repo_name}</TableCell> 
                                            </TableRow> : ''
                                        }
                                        <TableRow key={index}>
                                            <TableCell>{date}</TableCell> 
                                            <TableCell><a href={commit.commit_url} target='blank'>{commit.commit_message}</a></TableCell>
                                        </TableRow>
                                    </>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
    );
}

export default CommitsLog;