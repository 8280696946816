import React, {useEffect, useState} from 'react';

import PlayerStats from '../playerStats/PlayerStats';
import BarGraph from '../barGraph/BarGraph';

// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BarChartIcon from '@mui/icons-material/BarChart';

import './PlayerCard.scss';

const styleObj = {
        "content": "",
        "backgroundSize": "inherit",
        "backgroundRepeat":"no-repeat",
        "position": "absolute",
        "top": "0",
        "right": "0",
        "bottom": "0",
        "left": "0",
        "opacity": ".08", 
        "max-height": "221px"
}

const PERMISSION_ROLES = ['staff', 'admin', 'superadmin'];

function PlayerCard({playerInfo, topScore, place, leetCode, setShowAcctModal, fromProfile, detail}) {

    let cardIcon = '';
    const host = window.location.host;
    const arr = host.split('.');    
    const subdomain = arr.length > 1 && arr[0] !== 'www' ? arr[0] : '';
    const verifiedRole = PERMISSION_ROLES.includes(playerInfo.role);

    if(subdomain) {
        cardIcon = require(`../../orgConfig/${subdomain}.json`).cardIcon;
    }

    if(topScore){
       styleObj["top"] = "40px";
    } else {
        styleObj["top"] = "0";
    }

    const backgroundImg = {
        "backgroundImage" : `url(${cardIcon})`
      }
    
    const backgroundLogo = Object.assign(backgroundImg, styleObj);
    

    const [showGraph, setShowGraph] = useState(false);

    const openGraph = (e) => {
        e.preventDefault();
        setShowGraph(!showGraph);
    }

    const openAcctModal = (e) => {
        e.preventDefault();
        setShowAcctModal(true);
    }

    return (
        <div className={'playerCard ' + (playerInfo.employed && 'playerCard-employed')}  >
            <div className="playerCard__notGraph" style={{"border-radius" : showGraph ? "10px 10px 0 0" : "10px", "paddingTop": verifiedRole || topScore ? "56px" : "20px"}}>
                <div style={backgroundLogo}></div>
                <div className="playerCard__playerInfo">
                    {verifiedRole && <div className="playerCard__role"> STAFF </div>}
                    {topScore && <div className="playerCard__topScore"> <span className="playerCard__position">#{place + 1}</span> This Week</div>}
                    
                        <div className="playerCard__name"> 
                            {playerInfo.name}
                        </div>
                        {playerInfo.employed && playerInfo.role != 'staff' && <div className="playerCard__employed"> Employed </div>}
                </div>
                <div className="playerCard__codewarsInfo">
                
                    <div className="playerCard__totalScore">
                        {playerInfo.cohort &&
                            <div className="playerCard__class"> 
                                <span className="playerCard__label">Class: </span>
                                <span className="playerCard__codewarsUsername">{playerInfo.cohort}</span>
                            </div>
                        }
                        <div className="playerCard__points">{playerInfo.honor + playerInfo.leetcode_points}</div>
                        <div className="playerCard__openBarGraph" data-testid="playerCard__openBarGraph" onClick={(e) => openGraph(e)}>
                            <BarChartIcon />
                        </div>
                    </div>
                    <div className="playerCard__stats">
                        <PlayerStats playerInfo={playerInfo} title="Points" type="codewars"/> 
                        <PlayerStats playerInfo={playerInfo} title="Commits" /> 
                    </div>
                    
                </div>
            </div>
            {showGraph &&
                <div className="playerCard__barGraphContainer">
                    <BarGraph userid={playerInfo.id} playerCard display={showGraph} setShowGraph={setShowGraph}/> 
                </div>
            }
            
        </div>
    );
}

export default PlayerCard;