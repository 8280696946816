import React, {useEffect, useState} from 'react';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import ProblemSet from '../../components/problemSet/ProblemSet';
import { Button } from '@material-ui/core';

import './ProblemSets.scss';
import { useHistory } from 'react-router-dom';

function ProblemSets({ userPermissions, orgConfig }) {
    console.log(orgConfig);
    const history = useHistory();

    if(userPermissions.length && !userPermissions.includes("staff")){
        history.push("/");
    }

    const [problemSets, setProblemSets] = useState({created:[], organization:[], codeTrack:[]});

    const assignableProblemSets=async()=>{
        try{
            const jwtToken =  localStorage.getItem('accessToken');
            const requestOptions = {
                headers: {'Authorization': `Bearer ${jwtToken}`},
            };
            let res = await fetch(`${process.env.REACT_APP_API}/problemSets/assignable`, requestOptions)
            let data = await res.json();
            if(res.ok){
                setProblemSets(data);
            } else {
                console.error(data);
            }
        } catch(error) {
            console.error(error);
        }
    }

    useEffect(() => {
        assignableProblemSets();
    }, []);

    return (
        <div className="problemSets">
            <h2>Assignable Problem Sets</h2>
            <div className="problemSets__createNewProblem">
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/problemSets/new')}
                >
                            Create New Problem Set
                </Button>
            </div>
            <ShadowBox title="Your Problem Sets">
            {problemSets.created.map((problemSet, index)=> {
                return (
                    <ProblemSet editable key={problemSet.title + index} problemSet={problemSet} />
                )
            })}
            </ShadowBox>
            <ShadowBox title="My Organization Problem Sets">
            {problemSets.organization.map((problemSet, index)=> {
                return (
                    <ProblemSet key={problemSet.title + index} problemSet={problemSet} />
                )
            })}
            </ShadowBox>
            <ShadowBox title="CodeTrack Problem Sets">
            {problemSets.codeTrack.map((problemSet, index)=> {
                return (
                    <ProblemSet key={problemSet.title + index} problemSet={problemSet} />
                )
            })}
            </ShadowBox>
        </div>
    );
}

export default ProblemSets;