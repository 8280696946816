import React, {useState, useEffect} from 'react';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';

import { Link } from "react-router-dom";

import LoadingCards from '../loadingCards/LoadingCards';
import PlayerCard from '../playerCard/PlayerCard';
import EmptyView from '../emptyView/EmptyView';

import './PlayerCardCollection.scss';

function PlayerCardCollection({fellows, setEmployedFellows, subdomain}) {

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ showEmployed, setShowEmployed ] = useState(false);
    const [orderBy, setOrderBy] = useState('totalPoints');
    const [ ascending, setAscending ] = useState(false);
    const [ maxPoints, setMaxPoints ] = useState(Infinity);
    const [ minPoints, setMinPoints ] = useState(-Infinity);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fellows.length > 0 && setLoading(false);
    }, [fellows])

    useEffect(async () => {

        if(showEmployed){
            let employed = [];
            if(sessionStorage.employedFellows ){
                employed = JSON.parse(sessionStorage.employedFellows);
            } else {
                let url = `${process.env.REACT_APP_API}/users/employed`;

                if(subdomain){
                    url += `/${subdomain}`
                }

                try {
                    employed = await fetch(url).then(res => res.json());
                    sessionStorage.setItem('employedFellows', JSON.stringify(employed))
                } catch (err){
                    console.error(err);
                }
            }
            setEmployedFellows(employed);
        } else {
            setEmployedFellows([]);
        }
      
    }, [showEmployed])

    const filterUsers = (e) => {
        setSearchTerm(e.target.value.toLowerCase())
    }

    let handleOrder=(e)=>{
        setOrderBy(e.target.value);
    }
    let handleAscending=(e)=>{
        setAscending(e.target.value);
    }

    let handleMin =(e)=>{
        setMinPoints(e.target.value)
    }

    let handleMax =(e)=>{
        setMaxPoints(Number(e.target.value))
    }

    let filteredBySearch = searchTerm ? fellows.filter((user)=>{
        return user.name?.toLowerCase().includes(searchTerm.toLowerCase());
      }) : fellows;

    let filterByRange = filteredBySearch.filter((user)=>{
        return user.points_this_month >= minPoints && user.points_this_month <= maxPoints;
    });

    if(orderBy){
        filterByRange.sort((a, b)=>{
            if(ascending){
                if(a[orderBy] !== b[orderBy]){
                    return a[orderBy] > b[orderBy] ? 1 : -1;
                } else {
                    return a.totalScore > b.totalScore ? 1 : -1;
                }
            } else {
                if(a[orderBy] !== b[orderBy]){
                    return b[orderBy] > a[orderBy] ? 1 : -1;
                } else {
                    return b.totalScore > a.totalScore ? 1 : -1;
                }
            }
        });
    }

    return (
        <div className="playerCardCollection"> 
                    <div className="playerCardCollection__header">
                        <h1>Fellows</h1>
                        <div className="playerCardCollection__order">
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Order by:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={orderBy}
                                    label="Order By"
                                    onChange={handleOrder}
                                    variant="outlined"
                                >
                                    <MenuItem value={'totalScore'}>Points All Time</MenuItem>
                                    <MenuItem value={'points_this_week'}>Points This Week</MenuItem>
                                    <MenuItem value={'points_this_month'}>Points This Month</MenuItem>
                                    <MenuItem value={'commits_this_week'}>Commits This Week</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="playerCardCollection__filter">
                            <TextField id="search-user" label="Search" variant="outlined" onChange={(e) => filterUsers(e)} />
                            {subdomain &&
                                <div className="playerCardCollection__showEmployed">
                                    <div className="playerCardCollection__showEmployedText">Include Employed</div>
                                    <Switch checked={showEmployed} onChange={()=> setShowEmployed(!showEmployed)} />
                                </div>  
                            }
                        </div>
                    </div>
                    
                    <div className="playerCardCollection__container">
                        {loading && <LoadingCards count={6} /> }
                        
                        {
                            filterByRange.map((userInfo, index)=> {
                                return (
                                    <Link  
                                        key={userInfo.name+index}
                                        to={{
                                            pathname: "/fellow/" + userInfo.id,
                                            state : {
                                                playerInfo: userInfo,
                                                },
                                            }
                                    }>
                                   
                                        <PlayerCard key={userInfo.name + index} playerInfo={userInfo} place={index}   />
                                    </Link>
                                )
                            })} 

                    </div>
                    {(filterByRange.length < 1 || (!filterByRange.length && searchTerm)) && !loading && 
                        <EmptyView text={"No Results"}/>
                    }
        </div>
    );
}
export default PlayerCardCollection;