import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import './SingleBadge.scss';

import badges from '../../data/badges.json';

function SingleBadge({badge = {}, score}) {
   
   if(!badge.url){
       switch (true) {
            case score >= 850:
                badge = badges['850PointsTotal'];
                break;
            case score >= 300:
                badge = badges['300PointsTotal'];
                break;
            case score >= 50:
                badge = badges['50PointsTotal'];
                break;
            case score >= 10:
                badge = badges['10PointsTotal'];
                break;
        }
    }
    
    return (
        <div className="singleBadge">
            { badge.url && 
              <Tooltip title={badge.message} arrow placement="top">
                <img src={badge.url} /> 
              </Tooltip>
            }
        </div>
    );
}

export default SingleBadge;