import React, { useState, useEffect } from 'react';

import LoadingCards from '../loadingCards/LoadingCards';
import PlayerCard from '../playerCard/PlayerCard';
import EmptyView from '../emptyView/EmptyView';

import { Link } from "react-router-dom";

import './TopScoresCollection.scss';

function TopScoresCollection({fellows, loading}) {
    
    const [topUsers, setTopUsers] = useState([]);

    useEffect(() => {
        if(fellows.length){
            setTopUsers([...fellows].sort((a,b) => a.points_this_week > b.points_this_week ? -1 : 1).slice(0, 3));
        }
    }, [fellows]);

    return (
        <div className="topScoresCollection"> 
            <div className="topScoresCollection__header">
                <h1>This Week's Top Scores</h1>
            </div>
            <div className="topScoresCollection__container">
                {!topUsers.length && loading && <LoadingCards count={3} />}
                {topUsers.length > 0 && !loading && 
                    <>
                        {topUsers.map((userInfo, index)=> {
                            return (
                                <Link  
                                    key={userInfo.name+index}
                                    to={{
                                    pathname: "/fellow/" + userInfo.id,
                                    state : {
                                         playerInfo: userInfo,
                                        },
                                }}>
                                    <PlayerCard playerInfo={userInfo} place={index} topScore   />
                                </Link>
                            )
                        })}
                    </>
                }
            </div>
            {topUsers.length < 1 && !loading &&  <EmptyView text="No Results" /> }
        </div>
    );
}

export default TopScoresCollection;