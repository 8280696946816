import React, { useState } from 'react';
import { Link } from "react-router-dom";

import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from '@material-ui/core/styles';

import EmailInput from '../components/inputs/EmailInput';

import './SecurityCodeGenerator.scss';

const useStyles = makeStyles((theme) => ({
    submit: {
      margin: "10px 0 !important",
    }

  }));

function SecrurityCodeGenerator({subdomain}) {

    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorsArr, setErrorsArr] = useState([]);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    let orgConfig = {};

    if(subdomain){
      orgConfig = require('../orgConfig/pursuit.json');
    }

    const setSecurityCode = () => {
        setLoading(true);
       
        if(errorsArr.length) {
            setErrorMessage("Please resolve inputs before submitting.");
            window.scrollTo(0, 0);
            setLoading(false);
            return;
          }
        
        if(!email) {
            setErrorMessage("Please provide your email.");
            window.scrollTo(0, 0);
            setLoading(false);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email})
          };

        fetch(`${process.env.REACT_APP_API}/securityCodeGenerator`, requestOptions)
            .then(res => res.json())
            .then( data => {
                setSuccess(true)
                setLoading(false);
                setErrorMessage('');
            }).catch( err => {
                setErrorMessage("There was an error. Please try again later.");
                setLoading(false);
            })
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
    }
   
    return (
        <div className="securityCodeGenerator">
            <h3>Generate a Security Code</h3>
            {success && 
             <>
                <p>
                    We've emailed you a new security code!
                </p>
                <Link className="securityCodeGenerator__redirect" to='/'>Home</Link>
                <Link className="securityCodeGenerator__redirect" to='/createPassword'>Create A Password</Link>
                <Link className="securityCodeGenerator__redirect" to='/login'>Login</Link>
            </>
            }
            {!success &&
            <>
            <p>
                You can edit your account data with a personal 6 digit code. 
                To get a code or reset your code, please click below.
            </p>
            {errorMessage && <div className="securityCodeGenerator-error"> {errorMessage} </div>}
            
            <EmailInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr} orgConfig={orgConfig} />
           
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={setSecurityCode}
                loading={loading}
              >
                  Generate New Code
              </LoadingButton>
              </>
            }

        </div>
    );
}

export default SecrurityCodeGenerator;