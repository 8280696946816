import React from 'react';

import './TrackProjectInstructions.scss';

function TrackProjectInstructions(props) {
    return (
        <div className="trackProjectInstructions" data-testid="commitsLog__addWebhook"> 
                Add a Webhook to Github track your portfolio here. 
                
                <p>
                    While looking for your first job, you're encouraged to put your energy and time into one great project. 
                    Putting your best work into one project gives you the best chance to get your work infront of potential employers.
                    If you would like to read more about making one great project, you can read about it <a href="/one-great-project" target="_blank">here</a>.
                </p>

                <h3>
                    Here are the steps for setting up your project with tracking: 
                </h3>

                <ol>
                    <li>Go to the Github repo of your project</li>
                    <li>Click on the 'Settings' tab</li>
                    <img src="/github_webhook_images/settings_tab.png" />
                    <li>Select the 'Webhooks' menu item on the left-hand side</li>
                    <img src="/github_webhook_images/webhooks_left_menu.png" />

                    <li>Click on 'Add webhook'</li>
                    <img src="/github_webhook_images/add_webhook_initial.png" />

                    <li>Paste the following URL in the 'Payload URL' field: https://codewars-tracker-be.herokuapp.com/github</li>
                    <img src="/github_webhook_images/paste_url.png" />

                    <li>Click on 'Add webhook' at the Bottom of the Form</li>
                    <img src="/github_webhook_images/add_webhook_final.png" />

                </ol>

                <p>
                    And that's it. Your future commits will now be logged to your Tracker Card. While quality is more important than quantity,
                    you should aim for 3-5 commits each week to improve your project.
                </p>
            </div>
    );
}

export default TrackProjectInstructions;